import { render, staticRenderFns } from "./excessInfo.vue?vue&type=template&id=21ca7916&scoped=true&"
import script from "./excessInfo.vue?vue&type=script&lang=js&"
export * from "./excessInfo.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "21ca7916",
  null
  
)

export default component.exports